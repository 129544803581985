import React from 'react';

import styles from './CustomModal.module.css';

interface CustomModal {
  isOpen: boolean;
  action: (modalState : boolean | null) => void;
  children: any;
};

const CustomModal: React.FC<CustomModal> = ({ isOpen, action, children }) => {
  if (isOpen) {
    return (
      <div className={styles.customModal}>
        <div onClick={() => action(false)} className={styles.customModalOverlay}></div>
        <div className={styles.modalContainer}>
          <span onClick={() => action(false)} className={styles.modalExit}></span>
          {children}
        </div>
      </div>
    );
  }

  return null;
};

export default CustomModal;
