import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { connect } from 'react-redux';

import { AppState } from './reducers/root';
import { getHomePage, getProductsPage, getCommunityPage, getSpacePage } from './actions/pages';
import { getSiteSettings } from './actions/site_settings';

import Home from './pages/Home/Home';
import MembershipPage from './pages/Membership/Membership';
import Community from './pages/Community/Community';
import TheSpace from './pages/TheSpace/TheSpace';

import Loader from './components/Loader/Loader';

const mapStateToProps = (state: AppState) => ({
  loadingSettings: state.settings.loading,
  settingsLoaded: state.settings.loaded,
  siteSettings: state.settings,

  loadingPages: state.pages.loading,
  pageLoadError: state.pages.error,
  homePage: state.pages.home,
  productsPage: state.pages.products,
  communityPage: state.pages.community,
  spacePage: state.pages.space
});

const dispatchProps = (dispatch: any) => ({
  getSiteSettings: () => dispatch(getSiteSettings()),
  getHomePage: () => dispatch(getHomePage()),
  getProductsPage: () => dispatch(getProductsPage()),
  getCommunityPage: () => dispatch(getCommunityPage()),
  getSpacePage: () => dispatch(getSpacePage())
});

type AppProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof dispatchProps>;

const App: React.FC<AppProps> = ({
  loadingSettings,
  settingsLoaded,

  loadingPages,
  pageLoadError,
  homePage,
  productsPage,
  communityPage,
  spacePage,

  getSiteSettings,
  getHomePage,
  getProductsPage,
  getCommunityPage,
  getSpacePage
}) => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => setShowLoader(false), 1500);

    // Retrieve required Pages
    // TODO: potentially move this into each page?
    if (!loadingPages && !pageLoadError) {
      if (!homePage) {
        getHomePage();
      }

      if (!productsPage) {
        getProductsPage();
      }

      if (!communityPage) {
        getCommunityPage();
      }

      if (!spacePage) {
        getSpacePage();
      }
    }

    if (!loadingSettings && !settingsLoaded) {
      getSiteSettings();
    }
  });

  if (showLoader || loadingPages || loadingSettings) {
    return <Loader />;
  }

  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>

        <Route exact path='/membership'>
          <MembershipPage />
        </Route>

        <Route exact path='/our-community'>
          <Community />
        </Route>

        <Route exact path='/the-space'>
          <TheSpace />
        </Route>

        <Route path='*'>
          <Home />
        </Route>
      </Switch>
    </Router>
  );
};

export default connect(
  mapStateToProps,
  dispatchProps
)(App);
