import gsap from 'gsap';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useWindowSize } from 'react-use';

import { Product } from '../../types/products';

import ProductSliderItem from '../ProductSliderItem/ProductSliderItem';

import styles from './ProductSlider.module.css';

interface ProductSliderProps {
  productList?: Product[];
  action: (modalState : boolean | null) => void;
};

const ProductSlider: React.FC<ProductSliderProps> = ({ productList, action }) => {
  const [selectedProduct, setSelectedProduct] = useState(0);
  const { width } = useWindowSize();

  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  const productSliderRef = React.useRef<HTMLHeadingElement>(null);

  const scrollToProductItem = (element: React.RefObject<HTMLHeadingElement>, projectIndex: number) => {
    let x = '';
    switch (projectIndex) {
      case 0: x = '0';
        break;
      case 1: x = isMobile ? '-' + (width - 16) + 'px'  : '-97vw';
        break;
      case 2: x = isMobile ? '-' + (width * 2 - 32) + 'px' : '-197vw';
        break;
    }

    gsap.to(element.current, {
      x: x,
      ease: 'power4.out',
      duration: 1
    });
  };

  const handleSelectProduct = (productId: number) => {
    if (!productList || productList.length <= 0) {
      return;
    }

    // Search projects by unique name
    const projectIndex = productList.findIndex(product => product.id === productId);

    if (projectIndex < 0) {
      return;
    }

    scrollToProductItem(productSliderRef, projectIndex);
    setSelectedProduct(projectIndex);
  };

  return (
    <div ref={productSliderRef} className={styles.productSlider}>
      {productList && productList.map((product, index) =>
        <ProductSliderItem
          key={product.id}
          onSelectProduct={handleSelectProduct}
          selectedProductIndex={selectedProduct}
          currentProductIndex={index}
          productList={productList}
          action={action} />
      )}
    </div>
  );
};

export default ProductSlider;