import { createStore, applyMiddleware, compose, Store } from 'redux';
import thunk from 'redux-thunk';

import rootReducer, { AppState } from './reducers/root';

// TODO: should this be removed outside development environment?
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Handles redux data storage.
 *
 * @returns {Store<AppState>} Returns store for application state.
 */
export default function configureStore(): Store<AppState> {
  return createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(thunk)
    )
  );
}