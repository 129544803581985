import React from 'react';

import { dangerouslyGenerateHTML } from '../../helpers';

import facebookIcon from '../../assets/icons/facebook-icon.png';
import instagramIcon from '../../assets/icons/instagram-icon.png';
import styles from './Footer.module.css';

interface FooterProps {
  content: string;
  address?: string;

  facebook_url?: string;
  instagram_url?: string;
};

const Footer: React.FC<FooterProps> = ({
  address,

  facebook_url,
  instagram_url
}) => {
  return (
    <footer>
      <div className={styles.footerContainer}>
        <div className={styles.footerLeftContainer}>
          <img src={require('../../assets/images/logo_final_version.png')} className={styles.footerLogo} alt="logo" />
        </div>

        <div className={styles.footerRightContainer}>
          <p className={styles.footerAddress} dangerouslySetInnerHTML={dangerouslyGenerateHTML(address)} />

          <ul className={styles.footerSocialIcons}>
            {facebook_url &&
              <li>
                <a href={facebook_url} target="_blank" rel="noopener noreferrer">
                  <img src={facebookIcon} alt="Facebook icon" />
                </a>
              </li>
            }
            {instagram_url &&
              <li>
                <a href={instagram_url} target="_blank" rel="noopener noreferrer">
                  <img src={instagramIcon} alt="Instagram icon" />
                </a>
              </li>
            }
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
