/**
 * Generates an object for use for html insertion.
 *
 * @param {string} htmlData Html string to convert.
 * @returns {object} Returns __html object.
 */
export function dangerouslyGenerateHTML(htmlData?: string) {
  return {__html: htmlData || ''};
};

/**
 * Formats string as phone number.
 *
 * @param {string} phoneNumber String number to convert.
 * @returns {string} Returns formatted string.
 */
export function formatPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) {
    return phoneNumber;
  }
  return phoneNumber.replace(/(\d{3})\D?(\d{4})\D?(\d{4})/, '$1 $2 $3');
}