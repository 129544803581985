import { Action, Dispatch } from 'redux';

import * as directus from '../services/directus-service';

import { HomePage, MembershipPage, CommunityPage, SpacePage } from '../types/pages';

export const ACTION_LOAD_HOME_PAGE = 'ACTION_LOAD_HOME_PAGE';
export const ACTION_LOAD_HOME_PAGE_SUCCESS = 'ACTION_LOAD_HOME_PAGE_SUCCESS';
export const ACTION_LOAD_HOME_PAGE_FAILURE = 'ACTION_LOAD_HOME_PAGE_FAILURE';

export const ACTION_LOAD_PRODUCTS_PAGE = 'ACTION_LOAD_PRODUCTS_PAGE';
export const ACTION_LOAD_PRODUCTS_PAGE_SUCCESS = 'ACTION_LOAD_PRODUCTS_PAGE_SUCCESS';
export const ACTION_LOAD_PRODUCTS_PAGE_FAILURE = 'ACTION_LOAD_PRODUCTS_PAGE_FAILURE';

export const ACTION_LOAD_COMMUNITY_PAGE = 'ACTION_LOAD_COMMUNITY_PAGE';
export const ACTION_LOAD_COMMUNITY_PAGE_SUCCESS = 'ACTION_LOAD_COMMUNITY_PAGE_SUCCESS';
export const ACTION_LOAD_COMMUNITY_PAGE_FAILURE = 'ACTION_LOAD_COMMUNITY_PAGE_FAILURE';

export const ACTION_LOAD_SPACE_PAGE = 'ACTION_LOAD_SPACE_PAGE';
export const ACTION_LOAD_SPACE_PAGE_SUCCESS = 'ACTION_LOAD_SPACE_PAGE_SUCCESS';
export const ACTION_LOAD_SPACE_PAGE_FAILURE = 'ACTION_LOAD_SPACE_PAGE_FAILURE';

interface ActionLoadPageHome extends Action {
  type: typeof ACTION_LOAD_HOME_PAGE;
}

interface ActionLoadHomePageSuccess extends Action {
  type: typeof ACTION_LOAD_HOME_PAGE_SUCCESS;
  payload: HomePage;
}

interface ActionLoadHomePageFailure extends Action {
  type: typeof ACTION_LOAD_HOME_PAGE_FAILURE;
  error: string;
}

interface ActionLoadProductsPage extends Action {
  type: typeof ACTION_LOAD_PRODUCTS_PAGE;
}

interface ActionLoadProductsPageSuccess extends Action {
  type: typeof ACTION_LOAD_PRODUCTS_PAGE_SUCCESS;
  payload: MembershipPage;
}

interface ActionLoadProductsPageFailure extends Action {
  type: typeof ACTION_LOAD_PRODUCTS_PAGE_FAILURE;
  error: string;
}

interface ActionLoadCommunityPage extends Action {
  type: typeof ACTION_LOAD_COMMUNITY_PAGE;
}

interface ActionLoadCommunityPageSuccess extends Action {
  type: typeof ACTION_LOAD_COMMUNITY_PAGE_SUCCESS;
  payload: CommunityPage;
}

interface ActionLoadCommunityPageFailure extends Action {
  type: typeof ACTION_LOAD_COMMUNITY_PAGE_FAILURE;
  error: string;
}

interface ActionLoadSpacePage extends Action {
  type: typeof ACTION_LOAD_SPACE_PAGE;
}

interface ActionLoadSpacePageSuccess extends Action {
  type: typeof ACTION_LOAD_SPACE_PAGE_SUCCESS;
  payload: SpacePage;
}

interface ActionLoadSpacePageFailure extends Action {
  type: typeof ACTION_LOAD_SPACE_PAGE_FAILURE;
  error: string;
}

export type PageActionTypes =
  ActionLoadPageHome | ActionLoadHomePageSuccess | ActionLoadHomePageFailure |
  ActionLoadProductsPage | ActionLoadProductsPageSuccess | ActionLoadProductsPageFailure |
  ActionLoadCommunityPage | ActionLoadCommunityPageSuccess | ActionLoadCommunityPageFailure |
  ActionLoadSpacePage | ActionLoadSpacePageSuccess | ActionLoadSpacePageFailure;

/**
 * Dispatches Home page load action.
 *
 * @returns {PageActionTypes} Returns ActionLoadHome action.
 */
function dispatchLoadHomePage(): ActionLoadPageHome {
  return {
    type: ACTION_LOAD_HOME_PAGE
  };
}

/**
 * Dispatches Home page load successfully action.
 *
 * @param {HomePage} page Page data that was retrieved.
 * @returns {PageActionTypes} Returns ActionLoadHomeSuccess action.
 */
function dispatchLoadHomePageSuccess(page: HomePage): ActionLoadHomePageSuccess {
  return {
    type: ACTION_LOAD_HOME_PAGE_SUCCESS,
    payload: page
  };
}

/**
 * Dispatches Home page load failure action.
 *
 * @param {Error} e Error that was retrieved.
 * @returns {PageActionTypes} Returns ActionLoadHomeFailure action.
 */
function dispatchLoadHomePageError(e: Error): ActionLoadHomePageFailure {
  return {
    type: ACTION_LOAD_HOME_PAGE_FAILURE,
    error: e.message
  };
}

/**
 * Dispatches Products page load action.
 *
 * @returns {PageActionTypes} Returns ACTION_LOAD_PRODUCTS_PAGE action.
 */
function dispatchLoadProductsPage(): ActionLoadProductsPage {
  return {
    type: ACTION_LOAD_PRODUCTS_PAGE
  };
}

/**
 * Dispatches Products page load successfully action.
 *
 * @param {MembershipPage} page Page data that was retrieved.
 * @returns {PageActionTypes} Returns ActionLoadHomeSuccess action.
 */
function dispatchLoadProductsPageSuccess(page: MembershipPage): ActionLoadProductsPageSuccess {
  return {
    type: ACTION_LOAD_PRODUCTS_PAGE_SUCCESS,
    payload: page
  };
}

/**
 * Dispatches Products page load failure action.
 *
 * @param {Error} e Error that was retrieved.
 * @returns {PageActionTypes} Returns ActionLoadHomeFailure action.
 */
function dispatchLoadProductsPageError(e: Error): ActionLoadProductsPageFailure {
  return {
    type: ACTION_LOAD_PRODUCTS_PAGE_FAILURE,
    error: e.message
  };
}

/**
 * Dispatches Community page load action.
 *
 * @returns {PageActionTypes} Returns ACTION_LOAD_COMMUNITY_PAGE action.
 */
function dispatchLoadCommunityPage(): ActionLoadCommunityPage {
  return {
    type: ACTION_LOAD_COMMUNITY_PAGE
  };
}

/**
 * Dispatches Community page load successfully action.
 *
 * @param {CommunityPage} page Page data that was retrieved.
 * @returns {PageActionTypes} Returns ActionLoadHomeSuccess action.
 */
function dispatchLoadCommunityPageSuccess(page: CommunityPage): ActionLoadCommunityPageSuccess {
  return {
    type: ACTION_LOAD_COMMUNITY_PAGE_SUCCESS,
    payload: page
  };
}

/**
 * Dispatches Community page load failure action.
 *
 * @param {Error} e Error that was retrieved.
 * @returns {PageActionTypes} Returns ActionLoadHomeFailure action.
 */
function dispatchLoadCommunityPageError(e: Error): ActionLoadCommunityPageFailure {
  return {
    type: ACTION_LOAD_COMMUNITY_PAGE_FAILURE,
    error: e.message
  };
}

/**
 * Dispatches Space page load action.
 *
 * @returns {PageActionTypes} Returns ACTION_LOAD_COMMUNITY_PAGE action.
 */
function dispatchLoadSpacePage(): ActionLoadSpacePage {
  return {
    type: ACTION_LOAD_SPACE_PAGE
  };
}

/**
 * Dispatches Space page load successfully action.
 *
 * @param {CommunityPage} page Page data that was retrieved.
 * @returns {PageActionTypes} Returns ActionLoadHomeSuccess action.
 */
function dispatchLoadSpacePageSuccess(page: SpacePage): ActionLoadSpacePageSuccess {
  return {
    type: ACTION_LOAD_SPACE_PAGE_SUCCESS,
    payload: page
  };
}

/**
 * Dispatches Space page load failure action.
 *
 * @param {Error} e Error that was retrieved.
 * @returns {PageActionTypes} Returns ActionLoadHomeFailure action.
 */
function dispatchLoadSpacePageError(e: Error): ActionLoadSpacePageFailure {
  return {
    type: ACTION_LOAD_SPACE_PAGE_FAILURE,
    error: e.message
  };
}

/**
 * Handles action for retrieving Home page.
 *
 * @returns {Promise} Returns Page asynchronously.
 */
export function getHomePage() {
  return async function(dispatch: Dispatch): Promise<void> {
    try {
      dispatch(dispatchLoadHomePage());

      // Make request to Directus API to retrieve Page content
      const response = await directus.getHomePage();

      if (response) {
        dispatch(dispatchLoadHomePageSuccess(response));
      }
    }
    catch (error) {
      dispatch(dispatchLoadHomePageError(error));
    }
  };
}

/**
 * Handles action for retrieving Products page.
 *
 * @returns {Promise} Returns Page asynchronously.
 */
export function getProductsPage() {
  return async function(dispatch: Dispatch): Promise<void> {
    try {
      dispatch(dispatchLoadProductsPage());

      // Make request to Directus API to retrieve Page content
      const response = await directus.getProductsPage();

      if (response) {
        dispatch(dispatchLoadProductsPageSuccess(response));
      }
    }
    catch (error) {
      dispatch(dispatchLoadProductsPageError(error));
    }
  };
}

/**
 * Handles action for retrieving Community page.
 *
 * @returns {Promise} Returns Page asynchronously.
 */
export function getCommunityPage() {
  return async function(dispatch: Dispatch): Promise<void> {
    try {
      dispatch(dispatchLoadCommunityPage());

      // Make request to Directus API to retrieve Page content
      const response = await directus.getCommunityPage();

      if (response) {
        dispatch(dispatchLoadCommunityPageSuccess(response));
      }
    }
    catch (error) {
      dispatch(dispatchLoadCommunityPageError(error));
    }
  };
}

/**
 * Handles action for retrieving Community page.
 *
 * @returns {Promise} Returns Page asynchronously.
 */
export function getSpacePage() {
  return async function(dispatch: Dispatch): Promise<void> {
    try {
      dispatch(dispatchLoadSpacePage());

      // Make request to Directus API to retrieve Page content
      const response = await directus.getSpacePage();

      if (response) {
        dispatch(dispatchLoadSpacePageSuccess(response));
      }
    }
    catch (error) {
      dispatch(dispatchLoadSpacePageError(error));
    }
  };
}