import React from 'react';

import styles from './Loader.module.css';

const Loader: React.FC = () => {
  return (
    <div className={styles.loaderCenter}>
      <div className={styles.loaderLogo}>
        <img src={require('../../assets/images/logo_final_version.png')} className={styles.logoImg} />
        <p>loading...</p>
      </div>
    </div>
  );
};

export default Loader;
