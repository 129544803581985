import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../reducers/root';
import { getPage } from '../../selectors/pages';
import { PAGE_NAMES, PAGE_URLS } from '../../constants';
import { CommunityPage } from '../../types/pages';

import Header from '../../components/Header/Header';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import TextOverlayBlock from '../../components/TextOverlayBlock/TextOverlayBlock';
import BasicTextLayout from '../../components/BasicTextLayout/BasicTextLayout';
import { setMenuActive } from '../../utilities/utilities';

const mapStateToProps = (state: AppState) => ({
  siteSettings: state.settings,
  currentPage: getPage(state, PAGE_NAMES.COMMUNITY) as CommunityPage
});

type CommunityProps = ReturnType<typeof mapStateToProps>;

const Community: React.FC<CommunityProps> = ({
  siteSettings,
  currentPage
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleToggleMenu = () => {
    setMenuActive(showMenu);
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    /* eslint-disable no-undef */
    document.body.className = '';
    document.body.classList.add('community-page');
    /* eslint-enable */
  }, []);

  if (!currentPage) {
    // TODO: handle page not found
    return null;
  }

  return (
    <>
      <Menu showMenu={showMenu} pageUrl={PAGE_URLS.COMMUNITY_URL} />

      <Header
        heading={currentPage.header_title}
        content={currentPage.header_content}
        headerImage={currentPage.header_image?.data.full_url}
        showMenu={showMenu}
        handleToggleMenu={handleToggleMenu}
        secondaryLayout
        customClass={'community-header'}
        textOverlay={['Like-minded', 'Supportive', 'Collaborative']} />

      <BasicTextLayout
        heading={currentPage.section_b_title}
        content={currentPage.section_b_content} />

      <TextOverlayBlock
        heading={currentPage.section_a_title}
        content={currentPage.section_a_content}
        cta_label={currentPage.section_a_cta_label}
        cta_link={currentPage.section_a_cta_link}
        backgroundImage={currentPage.section_a_image?.data.full_url} />

      <Footer
        content={currentPage.footer.content}
        address={siteSettings.business_address}
        facebook_url={siteSettings.facebook_url}
        instagram_url={siteSettings.instagram_url} />
    </>
  );
};

export default connect(
  mapStateToProps
)(Community);
