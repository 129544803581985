import React from 'react';
import { Row, Col } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';

import { Product } from '../../types/products';
import styles from './ProductGrid.module.css';
import ProductGridItem from '../ProductGridItem/ProductGridItem';

interface ProductGridProps {
  products?: Product[];
  action: (modalState : boolean | null) => void;
};

const ProductGrid: React.FC<ProductGridProps> = ({ products, action }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <div className={styles.productGridContainer}>
      <h2>Membership options.</h2>

      <Row>
        {products?.sort((a, b) => (a.order && b.order && a.order > b.order) ? 1 : -1).map((product, index) =>
          <Col xs='12' md={isTablet && index === 2 ? '12' : '6'} lg='4' key={index}>
            <ProductGridItem
              product={product}
              action={action} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ProductGrid;
