import React from 'react';

import { Product } from '../../types/products';

import styles from './ProductSliderItem.module.css';

interface ProductSliderItemProps {
  productList: Product[];
  selectedProductIndex: number;
  currentProductIndex: number;
  onSelectProduct: (productId: number) => void;
  action: (modalState : boolean | null) => void;
};

const ProductSliderItem: React.FC<ProductSliderItemProps> = ({
  productList,
  currentProductIndex,
  selectedProductIndex,
  onSelectProduct,
  action }) => {

  if (!productList || productList.length <= 0) {
    return null;
  }

  const currentFeature = productList[currentProductIndex];

  return (
    <div className={styles.productSliderContainer}>
      <div className={styles.productContentContainer}>
        <div className={styles.productContent}>
          {currentFeature.heading &&
            <h2>{currentFeature.heading}</h2>
          }

          {currentFeature.features &&
            <ul>
              {currentFeature.features && currentFeature.features.map((feature, index) =>
                <li key={index}>{feature.description}</li>
              )}
            </ul>
          }

          <a href={currentFeature.product_link} className='contentButton'>Book now</a>
        </div>

        {productList &&
          <div className={styles.productSelection}>
            <ul>
              {productList.map((product, index) =>
                <li key={product.id} className={selectedProductIndex === index ? styles.selected : ''}>
                  <button onClick={() => onSelectProduct(product.id)}></button>
                </li>
              )}
            </ul>
          </div>
        }
      </div>

      <div className={styles.productImageContainer}>
        <div className={styles.productImage} style={{ backgroundImage: `url("${currentFeature.image?.data?.full_url}")` }}>
        </div>
      </div>
    </div>
  );
};

export default ProductSliderItem;
