import React from 'react';

import { dangerouslyGenerateHTML } from '../../helpers';

import styles from './BasicTextLayout.module.css';

interface BasicTextLayoutProps {
  heading?: string;
  content?: string;
  cta_label?: string;
  cta_link?: string;
};

const BasicTextLayout: React.FC<BasicTextLayoutProps> = ({ heading, content, cta_label, cta_link }) => {

  return (
    <div className='section'>
      <div className={styles.contentContainer}>
        <div className={styles.centerContainer}>
          <div>
            <h1>{heading}</h1>
          </div>
          <div className={styles.content}>
            <span dangerouslySetInnerHTML={dangerouslyGenerateHTML(content)}></span>
            {cta_label && cta_link &&
              <a href={cta_link}>{cta_label}</a>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicTextLayout;
