import {
  SettingsActionTypes,

  ACTION_LOAD_SETTINGS,
  ACTION_LOAD_SETTINGS_SUCCESS,
  ACTION_LOAD_SETTINGS_FAILURE
} from '../actions/site_settings';

import { SiteSettings } from '../types/common';

// Define redux store structure for this reducer.
export interface SettingsState extends SiteSettings {
  loading: boolean;
  loaded: boolean;
  errorLoading: string | null;
};

// Define initial redux state values for this reducer.
export const initialState: SettingsState = {
  loading: false,
  loaded: false,
  errorLoading: null
};

/**
 * Settings redux reducer.
 *
 * @param {any} state Current state.
 * @param {SettingsActionTypes} action Action that was executed.
 * @returns {SettingsState} Returns resulting state after action execution.
 */
export function settingsReducer(state: SettingsState = initialState, action: SettingsActionTypes): SettingsState {
  switch (action.type) {
    case ACTION_LOAD_SETTINGS:
      return {
        ...state,
        loading: true,
        loaded: false,
        errorLoading: null
      };

    case ACTION_LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        loading: false
      };

    case ACTION_LOAD_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errorLoading: action.error
      };

    default:
      return state;
  }
}