import {
  PageActionTypes,

  ACTION_LOAD_HOME_PAGE,
  ACTION_LOAD_HOME_PAGE_SUCCESS,
  ACTION_LOAD_HOME_PAGE_FAILURE,

  ACTION_LOAD_PRODUCTS_PAGE,
  ACTION_LOAD_PRODUCTS_PAGE_SUCCESS,
  ACTION_LOAD_PRODUCTS_PAGE_FAILURE,

  ACTION_LOAD_COMMUNITY_PAGE,
  ACTION_LOAD_COMMUNITY_PAGE_SUCCESS,
  ACTION_LOAD_COMMUNITY_PAGE_FAILURE,

  ACTION_LOAD_SPACE_PAGE,
  ACTION_LOAD_SPACE_PAGE_SUCCESS,
  ACTION_LOAD_SPACE_PAGE_FAILURE
} from '../actions/pages';

import { HomePage, MembershipPage, CommunityPage, SpacePage } from '../types/pages';

// Define redux store structure for this reducer.
export interface PageState {
  loading: boolean;
  home: HomePage | null;
  products: MembershipPage | null;
  community: CommunityPage | null;
  space: SpacePage | null;
  error: string | null;
};

// Define initial redux state values for this reducer.
export const initialState: PageState = {
  loading: false,
  home: null,
  products: null,
  community: null,
  space: null,
  error: null
};

/**
 * Pages redux reducer.
 *
 * @param {any} state Current state.
 * @param {PageActionTypes} action Action that was executed.
 * @returns {PageState} Returns resulting state after action execution.
 */
export function pagesReducer(state: PageState = initialState, action: PageActionTypes): PageState {
  switch (action.type) {
    case ACTION_LOAD_HOME_PAGE:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ACTION_LOAD_HOME_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        home: action.payload as HomePage
      };

    case ACTION_LOAD_HOME_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case ACTION_LOAD_PRODUCTS_PAGE:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ACTION_LOAD_PRODUCTS_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload as MembershipPage
      };

    case ACTION_LOAD_PRODUCTS_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case ACTION_LOAD_COMMUNITY_PAGE:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ACTION_LOAD_COMMUNITY_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        community: action.payload as CommunityPage
      };

    case ACTION_LOAD_COMMUNITY_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case ACTION_LOAD_SPACE_PAGE:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ACTION_LOAD_SPACE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        space: action.payload as SpacePage
      };

    case ACTION_LOAD_SPACE_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}