import React, { useEffect } from 'react';
import gsap from 'gsap';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { dangerouslyGenerateHTML } from '../../helpers';

import styles from './TextOverlayBlock.module.css';
import { useIntersection } from 'react-use';

interface TextOverlayBlockProps {
  heading?: string;
  content?: string;
  backgroundImage?: string;
  cta_label?: string;
  cta_link?: string;
  alternate?: boolean;
  action?: (modalState : boolean | null) => void;
};

const TextOverlayBlock: React.FC<TextOverlayBlockProps> = ({
  heading,
  content,
  backgroundImage,
  cta_label,
  cta_link,
  alternate,
  action
}) => {
  const history = useHistory();

  const textOverlayBlockRef = React.useRef<HTMLDivElement>(null);
  const backgroundImageRef = React.useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  const intersection = useIntersection(textOverlayBlockRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.7
  });

  const fadeIn = (element: React.RefObject<HTMLHeadingElement>) => {
    gsap.to(element.current,
      {
        duration: 0.8,
        ease: 'power4.out',
        y: -80,
        opacity: 1,
      });
  };

  const growHeight = (element: React.RefObject<HTMLDivElement>) => {
    gsap.to(element.current,
      {
        duration: 2,
        ease: 'power4.out',
        height: isMobile ? '300px' : '100%',
        transformOrigin: 'bottom'
      });
    fadeIn(textOverlayBlockRef);
  };

  useEffect(
    () => {
      if (intersection && intersection.intersectionRatio > 0) {
        growHeight(backgroundImageRef);
        //fadeIn(textOverlayBlockRef);
      }
    },
    [intersection]
  );

  const redirectToPage = (url: string) => {
    /* eslint-disable no-undef */
    window.scrollTo(0, 0);
    /* eslint-enable */

    history.push(url);
  };

  return (
    <div ref={textOverlayBlockRef} className={`section ${styles.overlayBlock}`}>
      <div className={`${styles.overlayBlockContainer} ${alternate && styles.alternate}`}>
        <div
          ref={backgroundImageRef}
          className={styles.overlayBlockImage}
          style={{ backgroundImage: `url("${backgroundImage}")` }}>
        </div>
        <div className={styles.overlayBlockContentContainer}>
          <div className={styles.overlayBlockContent}>
            <h2>{heading}</h2>

            <div dangerouslySetInnerHTML={dangerouslyGenerateHTML(content)}></div>

            {cta_label && (cta_link || action) &&
              <button className="contentButton" onClick={() => action ? action(true) : (cta_link && redirectToPage(cta_link))}> {cta_label}</button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

TextOverlayBlock.defaultProps = {
  alternate: false
};

export default TextOverlayBlock;
